import style from '@styles/home-page.module.css'
import ConnectDropdown from 'components/shared/connect-dropdown'
import { useWallet } from 'contexts/wallet.context'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function Home () {
  const router = useRouter()
  const { connected, connecting } = useWallet()

  useEffect(() => {
    if (connected && !connecting) {
      router.push('/dashboard/step1')
    }
  }, [connected])

  return (
    <>
      <main className={style.wrapper}>
        <div
          style={{
            paddingLeft: '20px',
            paddingTop: '28px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Link href='/'>
            <div
              style={{
                zIndex: 20
              }}
            >
              <img
                className='w-[230px] h-[170px]'
                src='/logo/small/index.png'
                alt='logo'
              />
            </div>
          </Link>
          <h3 className='mt-1'>Veritas vos liberabit</h3>
          <h4>- The truth will set you free -</h4>
          <ConnectDropdown />
        </div>
      </main>
    </>
  )
}
